import { createSlice } from "@reduxjs/toolkit";
import { DashboardTypes } from "./data";

const initialState: DashboardTypes = {
  datasetLoading: false,
  drawerVisible: true,
  activeTabKey: "1",
  uploaderVisible: false,
  dataTableActiveKey: "",
  dataTableVisible: false,
  carbonSavingsData: [],
  carbondataVisible: true,
  dfesRunSenario: true,
  playRunScenario: true,
  size: {
    width: 360,
    height: window.innerHeight,
  },
};

const dashboardModel = createSlice({
  name: "deckgl",
  initialState,
  reducers: {
    setDatasetLoading(state, { payload }) {},
    setDrawerVisible(state, { payload }) {
      state.drawerVisible = payload;
    },
    setUploaderVisible(state, { payload }) {
      state.uploaderVisible = payload;
    },
    setActiveTabKey(state, { payload }) {
      state.activeTabKey = payload;
    },
    setDataTableActiveKey(state, { payload }) {
      state.dataTableActiveKey = payload;
    },
    setDataTableVisible(state, { payload }) {
      state.dataTableVisible = payload;
    },
    setSize(state, { payload }) {
      state.size = payload;
    },
    setCarbonSavingsData(state, {payload}) {
      state.carbonSavingsData = payload;
    },
    setcarbondataVisible(state, {payload}) {
      state.carbondataVisible = payload;
    },
    setdfesRunSenario(state, {payload}) {
      state.dfesRunSenario = payload;
    },
    setPlayRunScenario(state, {payload}) {
      state.playRunScenario = payload;
    }
  },
});

export const {
  setDatasetLoading,
  setDrawerVisible,
  setUploaderVisible,
  setActiveTabKey,
  setDataTableActiveKey,
  setDataTableVisible,
  setCarbonSavingsData,
  setcarbondataVisible,
  setdfesRunSenario,
  setPlayRunScenario,
  setSize,
} = dashboardModel.actions;

export default dashboardModel.reducer;
