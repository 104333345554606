import { Result, Typography } from "antd";

const NoPage = () => {
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Result
        status="404"
        title={<Typography.Title>Page Not Found</Typography.Title>}
        subTitle={
          <Typography.Title level={3}>
            Oops..Looks like the page you are looking for does not exixts.
          </Typography.Title>
        }
      />
    </div>
  );
};

export default NoPage;
