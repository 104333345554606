import { configureStore, combineReducers } from "@reduxjs/toolkit";
import {
  globalModel,
  userModel,
  dashboardModel,
  deckglModel,
  reportModel,
  accessModel,
  displayEntityModel,
  analyticsModel,
} from "./model";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { encryptTransform } from "redux-persist-transform-encrypt";

const persistConfig = {
  key: "root",
  storage,
  blacklist: [
    "user",
    "deckgl",
    "dashboard",
    "reports",
    "access",
    "global",
    "displayEntity",
    "analytics",
  ],
  transforms: [
    encryptTransform({
      secretKey: process.env.REACT_APP_FIREBASE_API_KEY || "ai_tl_secretKey",
      onError: (err) => {
        console.log(err);
      },
    }),
  ],
};

const userPersistConfig = {
  key: "user",
  storage,
  blacklist: ["notification", "data", "uploadData"],
  transforms: [
    encryptTransform({
      secretKey: process.env.REACT_APP_FIREBASE_API_KEY || "ai_tl_secretKey",
      onError: (err) => {
        console.log(err);
      },
    }),
  ],
};

const reducers = combineReducers({
  user: persistReducer(userPersistConfig, userModel),
  dashboard: dashboardModel,
  deckgl: deckglModel,
  reports: reportModel,
  global: globalModel,
  access: accessModel,
  displayEntity: displayEntityModel,
  analytics: analyticsModel,
});

const persistedReducer = persistReducer(persistConfig, reducers);

const actionSanitizer = (action: any) => {
  switch (true) {
    case action.type === "analytics/setQueryTableData":
      return typeof action.payload !== "undefined"
        ? { ...action, payload: "<<LONG_BLOB>>" }
        : { ...action, results: "<<LONG_BLOB>>" };

    default:
      return action;
  }
};

const stateSanitizer = (state: any) =>
  state?.analytics
    ? {
        ...state,
        analytics: {
          ...state.analytics,
          query_table_data: "<<LONG BLOB>>",
        },
      }
    : state;

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: false,
      serializableCheck: false,
    }).concat(),
  devTools:
    process.env.NODE_ENV === "development"
      ? {
          actionSanitizer,
          stateSanitizer,
        }
      : false,
});

export const persistor = persistStore(store);
export default store;
