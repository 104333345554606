import { Alert, Button, Input, Modal, Space, Typography } from "antd";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateRevokeDatasetAccessVisible } from "../../model";
import { StateTypes } from "../../model/data";

const RevokeAccessModal = () => {
  const { revokeDatasetAccessVisible: visible, selectedUser } = useSelector(
    (state: StateTypes) => state.access
  );
  const [confirmInput, setConfirmInput] = useState("");

  const dispatch = useDispatch();

  const handleCancel = () => {
    setConfirmInput("");
    dispatch(updateRevokeDatasetAccessVisible(false));
  };

  const handleApplyChanges = () => {
    // set the access for the respected user
  };

  const accessInfo = [
    "View this dataset on their map or table",
    "Change configurations to the dataset",
    "Filter or analyse this dataset",
    "Download or export this dataset",
  ];

  const footer = (
    <Space
      direction="horizontal"
      style={{ width: "100%", justifyContent: "space-between" }}
    >
      <div style={{ marginBottom: 0, padding: 2, width: 150 }}>
        <Input
          value={confirmInput}
          onChange={(e) => setConfirmInput(e.target.value)}
          placeholder="revoke access"
        />
      </div>
      <div>
        <Button
          type="primary"
          className="primary-btn"
          disabled={confirmInput !== "revoke access"}
          onClick={handleApplyChanges}
        >
          Apply Changes
        </Button>
        <Button onClick={handleCancel}>Discard Changes</Button>
      </div>
    </Space>
  );

  return (
    <Modal
      title="Revoke Access"
      className="revoke-access-modal"
      visible={visible}
      onCancel={handleCancel}
      footer={footer}
      maskClosable={false}
    >
      <Typography.Paragraph strong>
        Revoke {selectedUser?.name ?? selectedUser?.email}'s access this dataset
      </Typography.Paragraph>

      <Alert
        showIcon
        type="warning"
        style={{ marginBottom: 10 }}
        message="Warning"
        description={
          <>
            <Typography.Text>
              By proceeding with this change you are revoking access for this
              dataset. This means {selectedUser?.name ?? selectedUser?.email}{" "}
              will not be able to
            </Typography.Text>
            <ul>
              {accessInfo.map((item: string, i: number) => (
                <li key={i}>
                  <Typography.Text>{item}</Typography.Text>
                </li>
              ))}
            </ul>
          </>
        }
      />

      <Typography.Paragraph strong style={{ marginBottom: 5 }}>
        Please confirm this change by typing "revoke access" in the textbox
        below
      </Typography.Paragraph>
    </Modal>
  );
};

export default RevokeAccessModal;
