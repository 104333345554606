import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

// import firebase config
import firebaseConfig from "../utils/firebase.config";

// initialize firebase auth
const app = firebase.initializeApp(firebaseConfig);
firebase.firestore(app).settings({
  experimentalAutoDetectLongPolling: true,
  merge: true,
});
export const auth = firebase.auth(app);
export const db = firebase.firestore(app);

// google provider
export const googleProvider = new firebase.auth.GoogleAuthProvider();
