import { createSlice } from "@reduxjs/toolkit";
import { ReportTypes } from "./data";

const initialState: ReportTypes = {
  reportList: [],
  currentReportTab: "",
  loading: false,
  reportModalVisible: false,
  editReportModalVisible: false,
  chartModalVisible: false,
  editChartModalVisible: false,
  selectedChart: null,
  shareReportModalVisible: false,
  fetched: false,
  dashboardDatasets: {},
};

const reportModel = createSlice({
  name: "reports",
  initialState,
  reducers: {
    addReport(state, { payload }) {
      state.reportList.push(payload);
      state.currentReportTab = payload.id;
    },
    deleteReport(state, { payload }) {
      const filteredReports = state.reportList.filter(
        (report) => report.id !== payload
      );
      if (state.currentReportTab === payload) {
        state.currentReportTab = state.reportList[0]?.id ?? "";
      }
      state.reportList = filteredReports;
    },
    updateReport(state, { payload }) {
      const report = state.reportList.filter(
        (report) => report.id === payload.id
      )[0];
      report.title = payload.title;
      report.description = payload.description;
    },
    setReports(state, { payload }) {
      state.reportList = payload.reportList;
      state.currentReportTab = payload.currentReportTab;
    },
    setCurrentReportTab(state, { payload }) {
      state.currentReportTab = payload;
    },
    addChart(state, { payload }) {
      const currentReport = state.reportList.filter(
        (report) => report.id === state.currentReportTab
      )[0];
      const chart = {
        ...payload,
        applyFilter: false,
      };
      currentReport.content.push(chart);
    },
    deleteChart(state, { payload }) {
      const currentReport = state.reportList.filter(
        (report) => report.id === state.currentReportTab
      )[0];
      currentReport.content = currentReport.content.filter(
        (chart: any) => chart.id !== payload
      );
    },
    updateChart(state, { payload }) {
      const currentReport = state.reportList.filter(
        (report) => report.id === state.currentReportTab
      )[0];
      const chartIndex = currentReport.content.findIndex(
        (chart: any) => chart.id === payload.id
      );
      currentReport.content[chartIndex] = payload;
    },
    toggleReportModal(state, { payload }) {
      state.reportModalVisible = payload;
    },
    toggleEditReportModal(state, { payload }) {
      state.editReportModalVisible = payload;
    },
    toggleChartModal(state, { payload }) {
      state.chartModalVisible = payload;
    },
    toggleEditChartModal(state, { payload }) {
      state.editChartModalVisible = payload;
    },
    toggleShareModal(state, { payload }) {
      state.shareReportModalVisible = payload;
    },
    toggleLoading(state, { payload }) {
      state.loading = payload;
    },
    toggleReportFetch(state, { payload }) {
      state.fetched = payload;
    },
    setDashboardDatasets(state, { payload }) {
      state.dashboardDatasets = payload;
    },
    setSelectedChart(state, { payload }) {
      state.selectedChart = payload;
    },
  },
});

export const {
  addReport,
  deleteReport,
  updateReport,
  setReports,
  toggleReportModal,
  toggleEditReportModal,
  toggleChartModal,
  toggleEditChartModal,
  setCurrentReportTab,
  addChart,
  deleteChart,
  updateChart,
  toggleLoading,
  toggleReportFetch,
  toggleShareModal,
  setDashboardDatasets,
  setSelectedChart,
} = reportModel.actions;

export default reportModel.reducer;
