import { useCallback, useEffect, useState } from "react";
import { Select, Space, Tree, Typography, Card, Spin } from "antd";
import { CaretDownOutlined } from "@ant-design/icons";
import { cloneDeep, isEmpty } from "lodash";

const { Option } = Select;
const { DirectoryTree } = Tree;

const ChooseAccess = ({ value, onChange, datasetID, checkedKeys }: any) => {
  return (
    <Select
      className="access-picker"
      value={value}
      onChange={(value: string) => onChange(value)}
      dropdownClassName="access-picker-dropdown"
      disabled={!checkedKeys.includes(datasetID)}
    >
      <Option value="view">can view</Option>
      <Option value="view&download">view & download</Option>
    </Select>
  );
};

const Datasets = ({
  searchedText,
  categories,
  setViewableDatasets,
  setExportableDatasets,
  checkedKeysValue
}: any) => {
  const [treeData, setTreeData] = useState<any[]>([]);
  const [dataList, setDataList] = useState<any[]>([]);
  const [expandedKeys, setExpandedKeys] = useState<any[]>([]);
  const [autoExpandParent, setAutoExpandParent] = useState(true);
  const [checkedKeys, setCheckedKeys] = useState<string[]>([]);
  useEffect(() => {
    setCheckedKeys(checkedKeysValue);
}, [checkedKeysValue])

  const getParentKey = useCallback((key: React.Key, tree: any[]): React.Key => {
    let parentKey: React.Key;
    for (let i = 0; i < tree.length; i++) {
      const node = tree[i];
      if (node.children) {
        if (node.children.some((item: any) => item.key === key)) {
          parentKey = node.key;
        } else if (getParentKey(key, node.children)) {
          parentKey = getParentKey(key, node.children);
        }
      }
    }
    return parentKey!;
  }, []);

  useEffect(() => {
    const generateList = (data: any[]): any[] => {
      return data.reduce((acc, node) => {
        if (isEmpty(node.children)) {
          const { key, title, isLeaf } = node;
          return [...acc, { key, title, isLeaf }];
        } else {
          return [...acc, ...generateList(node.children)];
        }
      }, []);
    };

    setDataList(generateList(categories));
    setTreeData(categories);
  }, [categories]);

  useEffect(() => {
    const expandedKeys = dataList
      .map((item: any) => {
        if (searchedText && item.title.toLowerCase().includes(searchedText)) {
          return getParentKey(item.key, categories);
        }
        return null;
      })
      .filter(
        (item: any, i: any, self: any) => item && self.indexOf(item) === i
      );

    if (searchedText) {
      const filterData = (array: any[], text: string) => {
        const getNodes = (result: any[], object: any) => {
          if (object.title.toLowerCase().includes(text)) {
            result.push(object);
            return result;
          }
          if (!object.isLeaf && object?.children) {
            const nodes = object.children.reduce(getNodes, []);
            if (nodes.length) result.push({ ...object, nodes });
          }
          return result;
        };

        return array.reduce(getNodes, []);
      };
      const filteredData = filterData(categories, searchedText);
      setTreeData(filteredData);
      setAutoExpandParent(true);
      setExpandedKeys(expandedKeys);
    } else {
      setTreeData(categories);
      setExpandedKeys([]);
      setAutoExpandParent(false);
    }
  }, [categories, dataList, getParentKey, searchedText]);

  useEffect(() => {
    const viewableDatasets: string[] = [];
    const exportableDatasets: string[] = [];

    const getNodes = (object: any) => {
      if (object.isLeaf && checkedKeys.includes(object.key)) {
        if (object.accessLevel === "view") viewableDatasets.push(object.key);
        else exportableDatasets.push(object.key);
        return;
      }
      object?.children?.forEach(getNodes);
    };

    treeData.forEach(getNodes);
    setViewableDatasets(viewableDatasets);
    setExportableDatasets(exportableDatasets);
  }, [treeData, checkedKeys, setViewableDatasets, setExportableDatasets]);

  const getDataset = (tree: any, key: string): any => {
    let dataset;
    for (let i = 0; i < tree.length; i++) {
      const node = tree[i];
      if (node.key === key) dataset = node;
      if (!isEmpty(node.children))
        dataset = getDataset(node.children, key) || dataset;
    }
    return dataset;
  };

  // const markSelectedDataset = (dataset: any, value: boolean) => {
  //   if (isEmpty(dataset.children)) {
  //     dataset.selected = value;
  //   } else {
  //     dataset.selected = value;
  //     for (let i = 0; i < dataset.children.length; i++)
  //       markSelectedDataset(dataset.children[i], value);
  //   }
  // };

  // const handleCheckboxClick = (e: any, key: string) => {
  //   const tree = cloneDeep(treeData);
  //   const dataset = getDataset(tree, key);
  //   markSelectedDataset(dataset, e.target.checked);
  //   setTreeData(tree);
  // };

  const setAccessLevel = (dataset: any, value: string) => {
    if (isEmpty(dataset.children)) {
      dataset.accessLevel = value;
    } else {
      dataset.accessLevel = value;
      for (let i = 0; i < dataset.children.length; i++)
        setAccessLevel(dataset.children[i], value);
    }
  };

  const handleAccessChange = (value: string, key: string) => {
    const tree = cloneDeep(treeData);
    const dataset = getDataset(tree, key);
    setAccessLevel(dataset, value);
    setTreeData(tree);
  };

  const onExpand = (expandedKeys: any, info: any) => {
    if (
      [
        "ant-select-selection-item",
        "ant-checkbox-input",
        "ant-select-item-option-content",
      ].includes(info.nativeEvent.srcElement.className)
    )
      return;
    let newExpandedKeys = [...expandedKeys];
    if (!info.expanded) {
      newExpandedKeys = expandedKeys.filter(
        (key: any) => !key.includes(info.node.key)
      );
    }
    // @ts-ignore
    setExpandedKeys(newExpandedKeys);
  };

  const handleCheck = (checkedKeys: any) => {
    setCheckedKeys(checkedKeys);
  };

  return (
    <Card
      className="datasets"
      title={<Typography.Text> Datasets</Typography.Text>}
      extra={
        <Typography.Title level={5} style={{ margin: 0 }}>
          {/* <ChooseAccess value="view" header /> */}
          Edit Access
        </Typography.Title>
      }
      headStyle={{
        backgroundColor: "var(--default-green)",
        color: "white",
        padding: "0px 24px",
      }}
      bodyStyle={{ overflowY: "scroll", height: "58.5vh" }}
      style={{ width: "31vw" }}
    >
      {isEmpty(treeData) && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spin tip="Loading datasets..." />
        </div>
      )}
      <DirectoryTree
        className="datasets-tree"
        treeData={treeData}
        onExpand={onExpand}
        expandedKeys={expandedKeys}
        checkedKeys={checkedKeys}
        autoExpandParent={autoExpandParent}
        showLine={{ showLeafIcon: false }}
        showIcon={true}
        switcherIcon={<CaretDownOutlined />}
        checkable
        onCheck={(checked: any) => handleCheck(checked)}
        titleRender={(info: any) => (
          <Space>
            <Typography.Text
              ellipsis
              style={{
                width: 220,
                marginLeft: 12,
                fontSize: !info.isLeaf ? 16 : 14,
              }}
            >
              {info.title}
            </Typography.Text>

            {/* <Checkbox
              checked={info.selected}
              onChange={(e) => handleCheckboxClick(e, info.key)}
              style={{ position: "absolute", right: 110, top: 0 }}
            /> */}
            <ChooseAccess
              value={info.accessLevel}
              onChange={(value: string) => handleAccessChange(value, info.key)}
              datasetID={info.key}
              checkedKeys={checkedKeys}
            />
          </Space>
        )}
      />
    </Card>
  );
};

export default Datasets;
