import { LeftOutlined } from "@ant-design/icons";
import { Breadcrumb, PageHeader, Typography } from "antd";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import Styled from "styled-components";
import { StateTypes } from "../../model/data";
import "../components.css";
import useQueryFromUrl from "../../hooks/useQueryFromURL";

const TitleText = Styled(Typography.Title)`
font-size:1.3rem !important;
margin:0px !important;
`;

// const InfoButton = Styled(Button)`
//   color: var(--default-green) !important;
//   background: none;
//   border-radius: 2px;
//   border: 1px solid var(--default-green);
//   height: 2.2rem;
//   font-size: 1rem;
//   line-height: 1.1rem;
//   font-weight: 500;
// `;

const AccessHeader = ({ heading, showBackBtn, showBreadcrumb }: any) => {
  const { selectedUser, permissionGroups } = useSelector(
    (state: StateTypes) => state.access
  );
  const history = useHistory();
  const match = useRouteMatch();
  const query: any = useQueryFromUrl().get("group");

  let userName = "Invalid User";
  if (!isEmpty(selectedUser)) userName = selectedUser.name;

  let groupName = "New Group";
  if (!isEmpty(query))
    groupName =
      permissionGroups.find((group) => group.id === query)?.name ?? "New Group";

  const breadcrumbNameMap: { [index: string]: string } = {
    "/access": "All Users",
    "/access/from-org": "Internal Users",
    "/access/external-collab": "External Users",
    "/access/pending-invites": "Pending Invitations",
    "/access/failed-invites": "Failed Invitations",
    "/access/base-permissions": "Default Permissions",
    "/access/invite-users": "Invite Users",
    "/access/invite-users/custom-permissions": groupName,
    "/access/:userId": userName,
    "/access/:userId/manage-access": "Manage Access",
    "/access/:userId/manage-access/custom-permissions": groupName,
  };

  const pathSnippets = match.path.split("/").filter((i) => i);
  const urlSnippets = match.url.split("/").filter((i) => i);

  const breadcrumbItems = pathSnippets.map((_, index) => {
    const route = `/${pathSnippets.slice(0, index + 1).join("/")}`;
    const url = `/${urlSnippets.slice(0, index + 1).join("/")}`;

    return (
      <Breadcrumb.Item key={route}>
        {route.endsWith("userId") || (route.endsWith("custom-permissions") && (
          <Typography.Text strong style={{ color: "rgba(0,0,0,0.45)" }}>
            {breadcrumbNameMap[route]}
          </Typography.Text>)
        )}
        {!route.endsWith("userId") && !route.endsWith("custom-permissions") && (
          <Link to={url}>{breadcrumbNameMap[route]}</Link>
        )}
      </Breadcrumb.Item>
    );
  });
  return (
    <PageHeader
      className="access-page-header"
      style={{
        background: "white",
        height: "12vh",
        padding: '25px 30px'
      }}
      breadcrumb={
        showBreadcrumb && (
          <Breadcrumb
            separator=">"
            style={{ marginLeft: 32, position: "absolute", marginTop: 28 }}
          >
            {breadcrumbItems}
          </Breadcrumb>
        )
      }
      onBack={() => history.goBack()}
      backIcon={showBackBtn ? <LeftOutlined /> : false}
      title={<TitleText>{heading !== "Overview" ? heading : null}</TitleText>}
      // extra={
      //   heading === "Overview" && (
      //     <InfoButton>10 of 15 users invited</InfoButton>
      //   )
      // }
    />
  );
};

export default AccessHeader;
