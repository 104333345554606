import { createSlice } from "@reduxjs/toolkit";
import { UserModelTypes } from "./data";

const initialState: UserModelTypes = {
  currentUser: {},
  loggedIn: false,
  data: {},
  uploadData: {},
  newUser: false,
  isAdmin: false,
  permissions: {
    meta: {},
    read: [],
    write: [],
  },
  dfesDescription: {},
  selectedTechnology: ""
};

const userModel = createSlice({
  name: "user",
  initialState,
  reducers: {
    saveUser(state, { payload }) {
      state.currentUser = payload;
      state.loggedIn = payload ? true : false;
    },

    saveData(state, { payload }) {
      state.data = payload;
    },

    updateData(state, { payload: { datasetId, data } }) {
      state.data[datasetId] = data;
    },

    clearUser: () => {
      window.localStorage.clear();
      return initialState;
    },

    setIsAdmin(state, { payload }) {
      state.isAdmin = payload;
    },

    updatePermissions(state, { payload }) {
      state.permissions = payload;
    },
    updateUploadData(state, { payload: { datasetId, data } }) {
      state.uploadData[datasetId] = data;
    },
    updateUploadDataProgress(state, { payload: { datasetId, progress } }) {
      state.uploadData[datasetId].loaded = progress;
    },
    setDfesDescription(state, {payload}) {
      state.dfesDescription = payload;
    },
    setSelectedTechnology(state, {payload}) {
      state.selectedTechnology = payload;
    }
  },
});

export const {
  saveUser,
  clearUser,
  saveData,
  updateData,
  setIsAdmin,
  updatePermissions,
  updateUploadData,
  updateUploadDataProgress,
  setDfesDescription,
  setSelectedTechnology
} = userModel.actions;
export default userModel.reducer;
