import {
  Alert,
  Button,
  Card,
  Dropdown,
  Input,
  Menu,
  message,
  Space,
  Typography,
} from "antd";
import { isEmpty } from "lodash";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { updateAccessSeletedUser, updateCurrentAccessTab } from "../../model";
import { StateTypes } from "../../model/data";
import { db } from "../../services/firebase";
import PermissionGroup from "./PermissionGroup";

const ManageAccess = () => {
  const { selectedUser, defaultPermission, permissionGroups } = useSelector(
    (state: StateTypes) => state.access
  );
  const permissionGroupName =
    selectedUser?.permissionGroup ?? defaultPermission.name;
  const userId = selectedUser?.key;

  const [selectedPermissionGroup, setSelectedPermissionGroup] = useState(
    permissionGroups.find((group) => group.name === permissionGroupName)
  );
  const [customMessage, setCustomMessage] = useState("");
  const [changingPermission, setChangingPermission] = useState(false);

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const handleSelectPermissionGroup = (selectedGroup: any) => {
    if (selectedGroup.name === "New Group") {
      history.push(`${location.pathname}/custom-permissions`);
    } else {
      setSelectedPermissionGroup(selectedGroup);
    }
  };

  const handleApplyChanges = () => {
    if (!userId || isEmpty(userId)) {
      message.warning("No user selected");
      return;
    }

    setChangingPermission(true);
    db.collection("user")
      .doc(userId)
      .update({
        permissionGroup: db.doc(`groups/${selectedPermissionGroup?.id}`),
      })
      .then(() => {
        message.success("Permission updated successfully!");
      })
      .catch((err) => {
        message.error("Error Occurred! Try again later.");
      })
      .finally(() => {
        setChangingPermission(false);
        history.goBack();
      });
  };

  const handleCancel = () => {
    dispatch(updateAccessSeletedUser(null));
    history.goBack();
    dispatch(updateCurrentAccessTab("users"))
  };

  const filteredPermissionGroups = permissionGroups.filter(
    (group: any) => group.id !== selectedPermissionGroup?.id
  );

  const editButtonHandler = (id: any) => {
    history.push(`${location.pathname}/custom-permissions?group=${id}`);
    dispatch(updateCurrentAccessTab("manage-permission"))
  };

  const menu = (
    <Menu>
      {filteredPermissionGroups.map((group: any) => (
        <Menu.Item
          key={group.id}
          onClick={() => handleSelectPermissionGroup(group)}
        >
          <PermissionGroup
            name={group.name}
            description={group.description}
            groupid={group.id}
            editHandler={(id: any) => editButtonHandler(id)}
          />
        </Menu.Item>
      ))}
    </Menu>
  );

  if (!selectedUser) {
    return <Redirect to="/access" />;
  }

  return (
    <div style={{ width: "100%", height: "100%", padding: "1.5rem 2.5rem", paddingTop: 0 }}>
      {/* <Typography.Title level={4}>Change User Permissions</Typography.Title> */}
      <Card style={{ width: "100%" }} bodyStyle={{ padding: "2rem 3rem" }}>
        <label htmlFor="permissionGroups">
          <Typography.Text strong style={{ fontSize: "1.1rem" }}>
            Permission Group
          </Typography.Text>
        </label>
        <Dropdown
          overlay={menu}
          overlayStyle={{
            width: "inherit",
            maxHeight: 240,
            overflowY: "scroll",
            border: "1px solid lightgrey",
            boxShadow: "5px 5px 5px rgba(0, 0, 0, 0.1)",
          }}
          placement="bottom"
          trigger={["click"]}
        >
          <PermissionGroup
            name={selectedPermissionGroup?.name || ""}
            description={selectedPermissionGroup?.description || ""}
            groupid={selectedPermissionGroup?.id || ""}
            editHandler={(id: any) => editButtonHandler(id)}
            isSelected
          />
        </Dropdown>
        <label htmlFor="message">
          <Typography.Text strong style={{ fontSize: "1.1rem" }}>
            Add a message (optional)
          </Typography.Text>
        </label>
        <div className="input-container">
          <Input.TextArea
            id="message"
            placeholder="Type here"
            autoSize={{ minRows: 5, maxRows: 7 }}
            value={customMessage}
            onChange={(e) => setCustomMessage(e.target.value)}
          />
        </div>
        <Alert
          type="warning"
          showIcon
          message="Note"
          description={
            <Typography.Text>
              Changing a user's permission group will overrite all previous
              permissions granted to a user.
            </Typography.Text>
          }
        />
        <Space
          direction="horizontal"
          style={{ width: "100%", justifyContent: "flex-end", marginTop: 24 }}
        >
          <Button
            type="primary"
            className="primary-btn"
            loading={changingPermission}
            onClick={handleApplyChanges}
            style={{ backgroundColor: "var(--default-green)" }}
          >
            Apply Changes
          </Button>
          <Button onClick={handleCancel}>Cancel</Button>
        </Space>
      </Card>
    </div>
  );
};

export default ManageAccess;
