import { ReactNode, useEffect, useState } from "react";
import { Badge, Tabs, Typography } from "antd";
import {
  // BankOutlined,
  TeamOutlined,
  // RocketOutlined,
  HistoryOutlined,
  // StopOutlined,
} from "@ant-design/icons";
// eslint-disable-next-line
import { updateAccessSeletedUser, updateCurrentAccessTab } from "../../model";
import { useDispatch, useSelector } from "react-redux";
import { Route, useHistory, useLocation } from "react-router-dom";
import { StateTypes } from "../../model/data";
import AccessHeader from "./AccessHeader";

const ACCESS_MENU_ITEMS: {
  id: number;
  key: string;
  label: any;
  path: string;
  icon: ReactNode;
}[] = [
  {
    id: 1,
    key: "users",
    label: "All Users",
    path: "/access",
    icon: <TeamOutlined style={{ marginRight: "0.5rem" }} />,
  },
  // {
  //   id: 2,
  //   key: "from_org",
  //   label: getLabel("from_org", "Internal Users", <BankOutlined style={{ marginRight: "0.5rem" }}/>),
  //   title: "Internal Users",
  //   path: "/access/from-org",
  // },
  // {
  //   id: 3,
  //   key: "external",
  //   label: getLabel("external", "External Users", <RocketOutlined style={{ marginRight: "0.5rem" }}/>),
  //    title: "External Users",
  // },
  {
    id: 4,
    key: "pending",
    label: "Pending Invitations",
    path: "/access/pending-invites",
    icon: <HistoryOutlined style={{ marginRight: "0.5rem" }} />,
  },
  // {
  //   id: 5,
  //   key: "failed",
  //   label: getLabel("failed", "Failed Invitations", <StopOutlined style={{ marginRight: "0.5rem" }} />),
  //   title: "Failed Invitations"
  //   path: "/access/failed-invites",
  // },
];

const AccessMenu = () => {

  const customPermissionRoutes: any = [
    "/access/invite-users/custom-permissions",
    "/access/:userId/manage-access/custom-permissions",
  ];

  const users: any = useSelector((state: StateTypes) => state.access.userList);
  const activeTab = useSelector((state: StateTypes) => state.access.currentTab);
  // eslint-disable-next-line
  const [selectedMenu, setSelectedMenu] = useState<any>(
    ACCESS_MENU_ITEMS[0].key
  );

  const dispatch = useDispatch();
  const history = useHistory();
  // const groupName = useGroupName();

  const location = useLocation();

  // useEffect to change Tabs when user navigates to a different route
  useEffect(() => {
    const path = location.pathname;
    if (path === "/access") {
      dispatch(updateCurrentAccessTab("users"));
    } else if (path === "/access/pending-invites") {
      dispatch(updateCurrentAccessTab("pending"));
    } else if (path === "/access/invite-users") {
      dispatch(updateCurrentAccessTab("invite-user"));
    } else if (path === "/access/:userId/manage-access") {
      dispatch(updateCurrentAccessTab("manage-access"));
    } else if (path === "/access/invite-users/custom-permissions") {
      dispatch(updateCurrentAccessTab("manage-permission"))
    } 
  }, [location.pathname, dispatch]);

  const getLabel = (key: string, value: string, icon: ReactNode) => {
    const numberOfUsers = users[key].length;
    return (
      <>
        <Typography.Text style={{ color: "inherit" }}>
          {icon}
          {value}
        </Typography.Text>

        {!isNaN(numberOfUsers) && (
          <Badge
            count={numberOfUsers}
            overflowCount={99}
            offset={[5, 0]}
            style={{
              backgroundColor: `${activeTab === key ? "black" : "white"}`,
              color: `${activeTab === key ? "white" : "black"}`,
            }}
          />
        )}
      </>
    );
  };

  // const handleMenuClick = (activeMenu: string, path: string) => {
  //   setSelectedMenu(activeMenu);
  //   history.push(path);
  //   dispatch(updateCurrentAccessTab(activeMenu));
  //   if (selectedUser) {
  //     dispatch(updateAccessSeletedUser(null));
  //   }
  // };

  // const handleInviteUsers = () => {
  //   history.push("/access/invite-users");
  // };

  const handleTabChange = (key: string) => {
    dispatch(updateCurrentAccessTab(key));
    if (key === "pending") {
      history.push("/access/pending-invites");
    } else if (key === "users") {
      history.push("/access");
    } else if (key === "invite-user") {
      history.push("/access/invite-users");
    } 
  };

  return (
    // <Space direction="vertical" className="access-sidebar">
    //   <Typography.Title
    //     style={{
    //       fontSize: "1.3rem",
    //       margin: 0,
    //     }}
    //   >
    //     Admin
    //   </Typography.Title>
    //   <Menu
    //     defaultSelectedKeys={[selectedMenu]}
    //     items={ACCESS_MENU_ITEMS}
    //     onSelect={({ key }) => {
    //       const item: any = ACCESS_MENU_ITEMS.find((item) => item.key === key);
    //       handleMenuClick(item.key, item.path);
    //     }}
    //   />
    // </Space>
    <>
      <Route path="/access" exact>
        <AccessHeader heading="Manage Users" />
      </Route>
      <Route path="/access/pending-invites" exact>
        <AccessHeader heading="Pending Invitations" />
      </Route>
      <Route path="/access/invite-users" exact>
        <AccessHeader heading="Invite Users" />
        {/* <InviteUsers /> */}
      </Route>
      <Route path="/access/:userId/manage-access" exact>
        <AccessHeader heading="Manage Access" />
      </Route>
      <Route path="/access/base-permissions" exact>
        <AccessHeader
          heading="Manage Base Permissions"
          showBackBtn
          showBreadcrumb
        />
      </Route>
      <Route path={customPermissionRoutes} exact>
        <AccessHeader heading="Manage Permissions" />
      </Route>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginRight: 45,
        }}
      >
        <Tabs
          className="access-tabs"
          activeKey={activeTab}
          onChange={(val) => handleTabChange(val)}
        >
          {ACCESS_MENU_ITEMS.map((item: any) => (
            <Tabs.TabPane
              key={item.key}
              tab={<span>{getLabel(item.key, item.label, item.icon)}</span>}
            >
              {/* <AllUserPage guidance={item.id === 1 ? guidance2?.userGuidance: guidance2.pendingGuidance}/> */}
            </Tabs.TabPane>
          ))}
          <Tabs.TabPane key="invite-user" tab="Invite Users"></Tabs.TabPane>
          {activeTab === "manage-permission" ? (
            <Tabs.TabPane
              key="manage-permission"
              tab="Manage Permissions"
            ></Tabs.TabPane>
          ) : null}
          {activeTab === "manage-access" ? (
            <Tabs.TabPane
            key="manage-access"
            tab="Manage Access"
            >
  
            </Tabs.TabPane>
          ) : null}
        </Tabs>
      </div>
    </>
  );
};

export default AccessMenu;
