import { AccessUser, AccessUsers } from "../../model/data.d";
import {
  USER_COLUMNS,
  PENDING_INVITATIONS_COLUMNS,
  FAILED_INVITATIONS_COLUMNS,
} from "./ColumnSchema";

const ORG = "advanced-infrastructure.co.uk";

const isUserFromOrg = (user: AccessUser) => {
  return user.email.split("@")[1] === ORG;
};

export const getUsersGroupByCategory = (users: AccessUser[]) => {
  const usersGroupedByCategory: AccessUsers = {
    users: [],
    from_org: [],
    external: [],
    pending: [],
    failed: [],
  };

  users.forEach((user: AccessUser) => {
    if (user.failed) usersGroupedByCategory.failed.push(user);
    else if (user.pending) usersGroupedByCategory.pending.push(user);
    else {
      usersGroupedByCategory.users.push(user);
      if (isUserFromOrg(user)) usersGroupedByCategory.from_org.push(user);
      else usersGroupedByCategory.external.push(user);
    }
  });

  return usersGroupedByCategory;
};

export const getColumns = (category: string) => {
  switch (category) {
    case "users":
      return USER_COLUMNS;
    case "from_org":
      return USER_COLUMNS;
    case "external":
      return USER_COLUMNS;
    case "pending":
      return PENDING_INVITATIONS_COLUMNS;
    case "failed":
      return FAILED_INVITATIONS_COLUMNS;
    default:
      return [];
  }
};

export const getActionItems = (category: string) => {
  switch (category) {
    case "users":
    case "from_org":
    case "external":
      return [
        {
          key: "manage-access",
          label: "Manage Access",
        },
        // {
        //   key: "revoke-access",
        //   label: "Revoke Access",
        // },
      ];

    case "pending":
      return [
        {
          key: "resend-invite",
          label: "Resend Invitation",
        },
        // {
        //   key: "revoke-access",
        //   label: "Revoke Access",
        // },
      ];

    case "failed":
      return [
        {
          key: "resend-invite",
          label: "Resend Invitation",
        },
        {
          key: "remove",
          label: "Remove",
        },
      ];

    default:
      return [];
  }
};

// export function useQueryFromUrl() {
//   const { search } = useLocation();
//   return useMemo(() => new URLSearchParams(search), [search]);
// }
