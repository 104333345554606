import {
  Alert,
  Button,
  Card,
  Dropdown,
  Input,
  Menu,
  message,
  notification,
  Space,
  Tag,
  Timeline,
  Typography,
} from "antd";
import axios from "axios";
import { isEmpty } from "lodash";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { addEmail, clearEmails, removeEmail, updateCurrentAccessTab } from "../../model";
import { PermissionGroupTypes, StateTypes } from "../../model/data";
import PermissionGroup from "./PermissionGroup";
import { db } from "../../services/firebase";
import { Md5 } from "ts-md5";
import { InfoCircleFilled } from "@ant-design/icons";

const guide = [
  {
    desc: "Invite one or more users to the platform. ",
  },
  {
    desc: "Add emails. Press ‘Enter’ after each email.",
  },
  {
    desc: "Select a permissions group. Choose from a list of pre-set permission groups, edit an existing permission group (note this will affect all users within that permission group), or create a new permission group.",
  },
  {
    desc: "Add an optional message",
  },
  {
    desc: "Send",
  },
];

const InviteUsers = () => {
  const {
    inviteUser: { emails },
    permissionGroups,
    defaultPermission,
    userList: { users, pending },
  } = useSelector((state: StateTypes) => state.access);
  const { uid } = useSelector((state: StateTypes) => state.user.currentUser);

  const [emailInput, setEmailInput] = useState("");
  const [selectedPermissionGroup, setSelectedPermissionGroup] =
    useState<PermissionGroupTypes>(defaultPermission);
  const [customMessage, setCustomMessage] = useState("");
  const [sendingInvite, setSendingInvite] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const enterMsg = "Press enter key";

  const handleChangeEmailInput = (e: any) => {
    const email = e.target.value;
    setEmailInput(email);
  };

  const handlePressSpace = (e: any) => {
    if (e.keyCode === 32) handleAddEmail(emailInput);
  };

  const validEmail = (email: string) => {
    return email.match(
      //eslint-disable-next-line
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  const emailExists = (email: string) => {
    return (
      users.find((user) => user.email.toLowerCase() === email.toLowerCase()) ||
      pending.find((user) => user.email.toLowerCase() === email.toLowerCase())
    );
  };

  const handleAddEmail = (email: string) => {
    const _email = email.toLowerCase();
    if (!validEmail(_email)) {
      message.warning("Invalid email!");
      return;
    }
    if (emailExists(_email)) {
      message.warning("Email already in use!");
      return;
    }
    dispatch(addEmail(_email));
    setEmailInput("");
  };

  const handleRemoveEmail = (email: string) => {
    dispatch(removeEmail(email));
  };

  const clearStates = () => {
    setEmailInput("");
    setCustomMessage("");
    dispatch(clearEmails());
  };

  const updateFirestore = async (emails: any) => {
    return Promise.all(
      emails.map((email: any) => {
        const userId = Md5.hashStr(email?.email.toLowerCase());
        const user = {
          active: false,
          email: email?.email,
          invite: {
            admin_id: uid,
            expiration_date: "",
            invited_on: new Date(),
            message: customMessage,
          },
          is_admin: false,
          permissionGroup: db.doc(`groups/${selectedPermissionGroup.id}`),
          temporary_password: email?.temporary_password,
        };
        return db.collection("user").doc(userId).set(user);
      })
    );
  };

  const handleSendInvitations = async () => {
    if (isEmpty(emails)) {
      message.warning("No emails entered!");
      return;
    }
    // send the invitation from here
    setSendingInvite(true);
    const users = emails.map((email: string) => ({
      email,
      username: Md5.hashStr(email.toLowerCase()),
    }));
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_INVITE_USERS}`,
        {
          users: users,
          permissionGroup: selectedPermissionGroup.id,
          message: customMessage,
        }
      );
      if (response.status === 200) {
        const { invited_users, existing_users } = response.data.data;
        await updateFirestore(invited_users);
        if (existing_users.length === 0) {
          notification.success({
            key: "user-invite",
            message: "Success",
            description: "Invite sent successfully!",
          });
        } else {
          notification.warning({
            key: "user-invite",
            message: "Warning",
            description: `Invite sent to ${
              invited_users.length
            } users.\n${existing_users.join(", ")} already exists!`,
            placement: "bottomRight",
          });
        }
      } else {
        notification.error({
          key: "user-invite",
          message: "Error",
          description: "Something went wrong. Try again later!",
          placement: "bottomRight",
        });
      }
    } catch (err) {
      notification.error({
        key: "user-invite",
        message: "Error",
        description: "Something went wrong. Try again later!",
        placement: "bottomRight",
      });
    } finally {
      setSendingInvite(false);
      clearStates();
      history.goBack();
    }
  };

  const handleCancel = () => {
    dispatch(clearEmails());
  };

  const handleSelectPermissionGroup = (selectedGroup: any) => {
    if (selectedGroup.name === "New Group") {
      history.push(`${location.pathname}/custom-permissions`);
    } else {
      setSelectedPermissionGroup(selectedGroup);
    }
  };

  const filteredPermissionGroups = permissionGroups.filter(
    (group: any) => group.id !== selectedPermissionGroup.id
  );

  const editButtonHandler = (id: any) => {
    history.push(`${location.pathname}/custom-permissions?group=${id}`);
    dispatch(updateCurrentAccessTab("manage-permission"))
  };

  const menu = (
    <Menu>
      {filteredPermissionGroups.map((group: any) => (
        <Menu.Item
          key={group.id}
          onClick={() => handleSelectPermissionGroup(group)}
        >
          <PermissionGroup
            name={group.name}
            description={group.description}
            groupid={group.id}
            editHandler={(id: any) => editButtonHandler(id)}
          />
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <div
      style={{
        width: "94%",
        height: "90vh",
        paddingTop: 0,
        display: "flex",
        backgroundColor: '#fff',
        marginLeft: 40
      }}
    >
      {/* <Typography.Title level={4}>Add users to your map</Typography.Title> */}
      <Card style={{ width: "74%", marginRight: 10, overflowY: "scroll" }} bodyStyle={{ padding: "1rem 4.5rem", height: '78vh', overflowY: 'scroll' }}>
        <label htmlFor="emails">
          <Typography.Text strong style={{ fontSize: "1.1rem" }}>
            Enter email(s)
          </Typography.Text>
        </label>
        <div className="input-container"
         style={{
          backgroundColor: '#DBE0EC',
          borderRadius: 4,
          marginBottom: 16,
          height: 50,
          marginTop: 10,
          border: '1px solid #6A7485'
         }}
        >
          {!isEmpty(emails) &&
            emails.map((email) => (
              <Tag
                key={email}
                closable
                color={emailExists(email) ? "red" : "blue"}
                onClose={(e) => {
                  e.preventDefault();
                  handleRemoveEmail(email);
                }}
              >
                {email}
              </Tag>
            ))}
          <Input
            placeholder="Enter email(s) and press enter key"
            type="email"
            id="emails"
            value={emailInput}
            onChange={handleChangeEmailInput}
            onKeyUp={handlePressSpace}
            onPressEnter={() => handleAddEmail(emailInput)}
          />
        </div>
        {validEmail(emailInput) ? (
          <p style={{ color: "orangered", marginTop: "-10px" }}>{enterMsg} </p>
        ) : null}
        <label htmlFor="permissionGroups">
          <Typography.Text strong style={{ fontSize: "1.1rem" }}>
            Permission group
          </Typography.Text>
        </label>
        <Dropdown
          overlay={menu}
          overlayStyle={{
            width: "inherit",
            maxHeight: 240,
            overflowY: "scroll",
            border: "1px solid lightgrey",
            boxShadow: "5px 5px 5px rgba(0, 0, 0, 0.1)",
          }}
          placement="bottom"
          trigger={["click"]}
        >
          <PermissionGroup
            name={selectedPermissionGroup.name}
            description={selectedPermissionGroup.description}
            groupid={selectedPermissionGroup.id}
            editHandler={(id: any) => editButtonHandler(id)}
            isSelected
          />
        </Dropdown>
        <label htmlFor="message">
          <Typography.Text strong style={{ fontSize: "1.1rem" }}>
            Add a message (optional)
          </Typography.Text>
        </label>
        <div 
         style={{
          marginTop: 10,
        }}
        >
          <Input.TextArea
            id="message"
            placeholder="Type here"
            autoSize={{ minRows: 5, maxRows: 5 }}
            value={customMessage}
            onChange={(e) => setCustomMessage(e.target.value)}
            style={{
              backgroundColor: '#DBE0EC',
              borderRadius: 4,
              marginBottom: 16,
              height: 50,
              marginTop: 10,
              border: '1px solid #6A7485'
             }}
          />
        </div>

        <Alert
          type="warning"
          showIcon
          message="Note"
          description={
            <>
              <Typography.Paragraph>
                Administrators must grant new users access permissions via a
                permission group before a new user can view or export a dataset.
                It is the responsibility of the administrator to ensure that
                users granted access to a dataset meet the license conditions of
                any third party data providers. All new users will be able to
                upload and view their own datasets.
              </Typography.Paragraph>
            </>
          }
          style={{
            padding: 12
          }}
        />

        <Space
          direction="horizontal"
          style={{ width: "100%", justifyContent: "flex-end", marginTop: 24 }}
        >
          <Button
            type="primary"
            // className="primary-btn"
            disabled={emails.length === 0}
            loading={sendingInvite}
            onClick={handleSendInvitations}
            style={{backgroundColor: emails.length === 0 ? "#d9d9d9" : "var(--default-green)"}}
          >
            Send Invitations
          </Button>
          <Button onClick={handleCancel}>Cancel</Button>
        </Space>
      </Card>
      <div
        style={{
          width: "26%",
        }}
      >
        <Space
          direction="vertical"
          size={24}
          className="assets-guide"
          style={{
            borderTop: "4px solid #4485EA",
            paddingTop: 15,
            paddingLeft: 20,

            backgroundColor: "#F3F4F8",
            height: "70vh",
            overflowY: "scroll",
            paddingBottom: 20,
            marginRight: 10
          }}
        >
           <Typography.Title level={4} style={{ margin: 0 }}>
            <InfoCircleFilled
              style={{ color: "var(--default-green)", marginRight: 10 }}
            />
            Guidance
          </Typography.Title>
          <Timeline>
            {guide.map((item: any, i: any) => (
              <Timeline.Item key={i}>
                <Space direction="vertical" size={4}>
                  <Typography.Text
                    style={{
                      fontSize: 16,
                      margin: 0,
                      fontWeight: 500,
                      lineHeight: 1.5,
                    }}
                  >
                     {item.desc}
                  </Typography.Text>
                  </Space>
              </Timeline.Item>
            ))}
          </Timeline>
        </Space>
      </div>
    </div>
  );
};

export default InviteUsers;
