import "./App.css";
import { Alert, Button, Card, Layout, Spin, Typography } from "antd";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import MainMenu from "./components/common/MainMenu";
import { DatasetProvider } from "./context/DatasetContext";
// import { Guidelines } from "./pages/Guidelines";
import PrivateRoute from "./components/common/PrivateRoute";
import { lazy, Suspense } from "react";
import { CloseOutlined } from "@ant-design/icons";
import Access from "./pages/Access";
import AdminRoute from "./components/common/AdminRoute";
import NoPage from "./pages/NoPage";
import { ErrorBoundary } from "react-error-boundary";

// pages import
const Login = lazy(() => import("./pages/Authentication/Login"));
const SignUp = lazy(() => import("./pages/Authentication/SignUp"));
const ForgotPassword = lazy(
  () => import("./pages/Authentication/ForgotPassword")
);
const Dashboard = lazy(() => import("./pages/Dashboard"));
const MapStudio = lazy(() => import("./pages/MapStudio"));
const Profile = lazy(() => import("./pages/Profile"));
const Reports = lazy(() => import("./pages/Reports"));
const VerifyEmail = lazy(() => import("./pages/Authentication/VerifyEmail"));
const ManageAuth = lazy(() => import("./pages/Authentication/ManageAuth"));
const Auth = lazy(() => import("./pages/Authentication/Auth"));
const Guidance = lazy(() => import("./pages/Guidance"));
const DataStories = lazy(() => import("./pages/DataStories"));
const StoryEditor = lazy(() => import("./components/stories/StoryEditor"));
const AnalyticsPage = lazy(() => import("./pages/AnalyticsPage"));

const { Content } = Layout;

function App() {
  return (
    <ErrorBoundary
      fallbackRender={({ error, resetErrorBoundary }) => (
        <div
          style={{ display: "grid", placeContent: "center", height: "100vh" }}
        >
          <Card
            title={
              <Typography.Title level={4}>
                An unexpected error occured
              </Typography.Title>
            }
            style={{ width: 450 }}
            extra={
              <Button
                icon={<CloseOutlined />}
                type="primary"
                onClick={() => resetErrorBoundary()}
              />
            }
          >
            <Alert
              type="error"
              message={error.name}
              description={error.message}
            />
          </Card>
        </div>
      )}
    >
      <Router>
        <Suspense
          fallback={
            <Spin
              size="large"
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%,-50%)",
              }}
            />
          }
        >
          <Switch>
            <Route path="/" exact component={Auth} />
            <Route path="/login" exact component={Login} />
            <Route path="/signup" exact component={SignUp} />
            <Route path="/forgotpassword" exact component={ForgotPassword} />
            <Route path="/verifyemail" exact component={VerifyEmail} />
            <Route path="/manageauth" exact component={ManageAuth} />

            <>
              <Layout>
                <MainMenu />
                <Layout>
                  <Content className="content" id="content">
                    {/* Passinf contest data to the child components */}
                    <DatasetProvider>
                      <Switch>
                        <PrivateRoute
                          path="/home"
                          exact
                          component={Dashboard}
                        />
                        <PrivateRoute
                          path="/map/:mapId"
                          exact
                          component={MapStudio}
                        />
                        <PrivateRoute
                          path="/dashboard"
                          exact
                          component={Reports}
                        />
                        <PrivateRoute
                          path="/profile"
                          exact
                          component={Profile}
                        />
                        <AdminRoute path="/access" component={Access} />
                        <PrivateRoute
                          path="/guidance"
                          exact
                          component={Guidance}
                        />
                        <PrivateRoute
                          path="/Analytics"
                          exact
                          component={AnalyticsPage}
                        />
                        <PrivateRoute
                          path="/stories-editor/:id"
                          exact
                          component={StoryEditor}
                        />
                        <PrivateRoute
                          path="/data-stories/:id"
                          exact
                          component={DataStories}
                        />
                        <Route path="*" component={NoPage} />
                      </Switch>
                    </DatasetProvider>
                  </Content>
                </Layout>
              </Layout>
            </>
          </Switch>
        </Suspense>
      </Router>
    </ErrorBoundary>
  );
}

export default App;
