import ReactDOM from "react-dom";
import App from "./App";
import "antd/dist/antd.css";
import "mapbox-gl/dist/mapbox-gl.css";
import { Provider } from "react-redux";
import store, { persistor } from "./Store";
import { PersistGate } from "redux-persist/lib/integration/react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

const queryClient = new QueryClient();

process.env.NODE_ENV === "production" &&
  Sentry.init({
    dsn: `${process.env.REACT_APP_SENTRY_DSN}`,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0.5,
    // beforeSend(event, hint) {
    //   if (event.exception) {
    //     Sentry.showReportDialog({ eventId: event.event_id });
    //   }
    //   return event;
    // },
  });

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
    <ReactQueryDevtools />
  </QueryClientProvider>,
  document.getElementById("root")
);
