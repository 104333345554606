import { InfoCircleFilled, SearchOutlined } from "@ant-design/icons";
import { Alert, Button, Card, Input, message, Space, Typography } from "antd";
import { isEmpty } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { addCategories, updateCurrentAccessTab } from "../../model";
import { StateTypes } from "../../model/data";
import { db } from "../../services/firebase";
import Datasets from "./Datasets";
import useQueryFromUrl from "../../hooks/useQueryFromURL";

const CustomPermissions = () => {
  const [searchedText, setSearchedText] = useState("");
  const [groupName, setGroupName] = useState("");
  const [groupDesc, setGroupDesc] = useState("");
  const [confirmText, setConfirmText] = useState("");
  const [viewableDatasets, setViewableDatasets] = useState<string[]>([]);
  const [exportableDatasets, setExportableDatasets] = useState<string[]>([]);
  const [creatingGroup, setCreatingGroup] = useState(false);
  const [permissionDatasets, setPermissionDatasets] = useState<any>([]);
  const [permissionwriteDatasets, setPermissionWriteDatasets] = useState<any>(
    []
  );
  const [checkedKeys, setCheckedKeys] = useState<any>([]);

  const { categories } = useSelector((state: StateTypes) => state.deckgl);
  const dispatch = useDispatch();
  const history = useHistory();

  const query: any = useQueryFromUrl().get("group");

  // useEffect(() => {
  //   // const unsubscribe = db
  //   const docRef = db.collection("groups").doc(query);
  //     // .collection("groups")
  //     // .doc(query)
  //     docRef.get().then((doc: any) => {
  //       if (doc.exists) {
  //         console.log("Document data:", doc.data());
  //       } else {
  //         console.log("No such document!");
  //     }
  //     })

  //   return () => unsubscribe();
  // }, [dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await db.collection("groups").doc(query).get();

        if (response.exists) {
          setGroupName(response.data()?.meta.name);
          setGroupDesc(response.data()?.meta.description);
          const checkedKeysValue = [
            ...response.data()?.read,
            ...response.data()?.write,
          ];
          setCheckedKeys(checkedKeysValue);
          setPermissionDatasets(response.data()?.read);
          setPermissionWriteDatasets(response.data()?.write);
        } else {
          console.log("No such document!");
        }
      } catch (err) {
        console.error(err);
      }
    };

    fetchData();
  }, [query]);

  useEffect(() => {
    const unsubscribe = db
      .collection("constants")
      .doc("categories")
      .onSnapshot((snapshot) => {
        dispatch(addCategories(snapshot.data()?.data));
      });

    return () => unsubscribe();
  }, [dispatch]);

  const modifyCategories = (array: any[], read: any, write: any) => {
    const getNodes = (result: any[], object: any) => {
      if (object.isLeaf) {
        const accesslevel = read.includes(object.key)
          ? "view"
          : write.includes(object.key)
          ? "view&download"
          : "view";
        result.push({ ...object, accessLevel: accesslevel });
        return result;
      }
      const nodes = object.children.reduce(getNodes, []);
      const accesslevel = nodes.every((c: any) => c?.accessLevel === "view")
        ? "view"
        : "view&download";
      result.push({
        ...object,
        children: nodes,
        accessLevel: accesslevel,
      });
      return result;
    };

    return array.reduce(getNodes, []);
  };

  const modifiedCategories = useMemo(
    () =>
      modifyCategories(categories, permissionDatasets, permissionwriteDatasets),
    [categories, permissionDatasets, permissionwriteDatasets]
  );

  const handleSearch = (e: any) => {
    setSearchedText(e.target.value);
  };

  const handleApplyChanges = () => {
    setCreatingGroup(true);
    const permissionGroup = {
      meta: {
        name: groupName,
        description: groupDesc, // Should add user_id/org_name for who created the permission group too, so that it is only accessible by the org members only?
      },
      read: viewableDatasets,
      write: exportableDatasets,
    };

    if (isEmpty(query)) createGroupInFirestore(permissionGroup);
    else updateGroupInFirestore(permissionGroup);
  };

  const createGroupInFirestore = (permissionGroup: any) => {
    // Should add interface for it?
    db.collection("groups")
      .add(permissionGroup)
      .then(() => {
        message.success("Permission group created!");
        history.goBack();
      })
      .catch((error) => {
        message.error("Error occurred! Try again later.");
      })
      .finally(() => {
        setCreatingGroup(false);
      });
  };

  const updateGroupInFirestore = (permissionGroup: any) => {
    // Should add interface for it?
    db.collection("groups")
      .doc(query)
      .update(permissionGroup)
      .then(() => {
        message.success("Permission group updated!");
        history.goBack();
      })
      .catch((error) => {
        message.error("Error occurred! Try again later.");
      })
      .finally(() => {
        setCreatingGroup(false);
      });
  };

  function handleCancel() {
    history.goBack();
   if ("/access/:userId/manage-access/custom-permissions") {
    dispatch(updateCurrentAccessTab("manage-access"));
   }
  }

  return (
    <div
      style={{
        width: "94%",
        height: "90vh",
        paddingTop: 0,
        display: "flex",
        backgroundColor: "#fff",
        marginLeft: 40,
      }}
    >
      <Card
        style={{ width: "76%", height: "100%", marginRight: 10 }}
        bodyStyle={{
          padding: "1.5rem 2.5rem",
          paddingTop: "1rem",
          height: "100%",
          paddingRight: 0,
        }}
      >
        <Space
          direction="horizontal"
          className="custom-permissions"
          style={{ width: "100%", height: "100%", overflowY: "scroll" }}
        >
          <div style={{ height: "100%" }}>
            <div
              className="input-container"
              style={{
                width: "34em",
                backgroundColor: "#DBE0EC",
                borderRadius: 4,
                marginBottom: 24,
                height: 40,
                border: "1px solid #6A7485",
              }}
            >
              <SearchOutlined />
              <Input
                placeholder="Search datasets"
                style={{ width: "100%" }}
                value={searchedText}
                onChange={handleSearch}
              />
            </div>
            <Datasets
              searchedText={searchedText}
              categories={modifiedCategories}
              setViewableDatasets={setViewableDatasets}
              setExportableDatasets={setExportableDatasets}
              checkedKeysValue={checkedKeys}
            />
          </div>
          <div
            style={{
              paddingLeft: "2rem",
              height: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div>
              {/* <Alert
                type="info"
                showIcon
                message={<Typography.Title level={5}>Note</Typography.Title>}
                description="Ensure that users meet licensing conditions to access all datasets selected, including datasets within a category. Click on a category name (e.g. Boundaries) to view child datasets within a category. "
                style={{ marginBottom: "0.5rem" }}
              /> */}
              <label htmlFor="group-name">
                <Typography.Text strong style={{ fontSize: "1.1rem" }}>
                  Group Name
                </Typography.Text>
              </label>
              <Input
                id="group-name"
                placeholder="Enter group name here"
                style={{
                  marginBlock: "0.5rem",
                  border: "1px solid #6A7485",
                  borderRadius: 4,
                  height: 35,
                  marginBottom: 15,
                 }}
                value={groupName}
                onChange={(e) => setGroupName(e.target.value)}
              />
              <label htmlFor="group-desc">
                <Typography.Text strong style={{ fontSize: "1.1rem" }}>
                  Group Description
                </Typography.Text>
              </label>
              <Input.TextArea
                id="group-desc"
                placeholder="Enter group description here"
                autoSize={{ minRows: 5, maxRows: 7 }}
                value={groupDesc}
                onChange={(e) => setGroupDesc(e.target.value)}
                style={{
                  marginBlock: "0.5rem",
                  border: "1px solid #6A7485",
                  borderRadius: 4,
                  marginBottom: 15,
                }}
              />
              <Typography.Paragraph>
                <Typography.Text strong>Note: </Typography.Text>
                <Typography.Text
                 style={{
                  margin: 0,
                  fontWeight: 500,
                }}
                >
                  Please ensure you confirm the permissions provisioned before
                  continuing with these changes. Type "confirm" to apply changes
                </Typography.Text>
              </Typography.Paragraph>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                // marginTop: 30,
              }}
            >
              <div style={{ marginBottom: 0, width: 80, padding: 0 }}>
                <Input
                  style={{
                    width: 250,
                    border: "1px solid rgb(208 227 255)",
                    borderRadius: 4,
                    height: 50,
                  }}
                  placeholder="Type confirm"
                  value={confirmText}
                  onChange={(e) => setConfirmText(e.target.value)}
                />
              </div>
              <Button
                type="primary"
                // className="primary-btn"
                loading={creatingGroup}
                disabled={confirmText.toLowerCase() !== "confirm"}
                onClick={handleApplyChanges}
                style={{
                  width: 150,
                  height: 50,
                  fontSize: 16, 
                  backgroundColor: confirmText.toLowerCase() === "confirm" ? "var(--default-green)" : "#d9d9d9"
                }}
              >
                Apply changes
              </Button>
            </div>
            <Alert
              type="info"
              showIcon
              message={<Typography.Title level={5}>IMPORTANT</Typography.Title>}
              description="It is the administrators responsibility to ensure that users meet the licensing conditions for enabled datasets."
              style={{ marginBottom: "0.5rem", marginTop: 30 }}
            />
            <div style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: 10,
              paddingBottom: 50,
              marginRight: 20
            }}>
            <Button onClick={handleCancel} style={{padding: "0 40px"}}>Cancel</Button>
            </div>
             
          </div>
        </Space>
      </Card>
      <div
        style={{
          width: "24%",
          padding: "10px 0",
          paddingRight: 10,
        }}
      >
        <Space
          direction="vertical"
          size={16}
          style={{
            borderTop: "4px solid #4485EA",
            padding: "15px 10px 20px 20px",
            width: "100%",
            backgroundColor: "#F3F4F8",
            height: "70vh",
            overflowY: "scroll",
            marginTop: 10
          }}
        >
          <Typography.Title level={4} style={{ margin: 0 }}>
            <InfoCircleFilled
              style={{ color: "var(--default-green)", marginRight: 10 }}
            />
            Guidance
          </Typography.Title>
          <Typography.Text
            style={{
              fontSize: 16,
              margin: 0,
              fontWeight: 500,
              lineHeight: 1.5,
            }}
          >
            Set data access permissions for a group. Dataset permissions can be
            set to: hidden, view only or view and export.
          </Typography.Text>
          <Typography.Text
            style={{
              fontSize: 16,
              margin: 0,
              fontWeight: 500,
              lineHeight: 1.5,
            }}
          >
            Click on a category
            name (e.g. Boundaries) to view child datasets within a category.
          </Typography.Text>
          <Typography.Text
            style={{
              fontSize: 16,
              margin: 0,
              fontWeight: 500,
              lineHeight: 1.5,
            }}
          >
             Permission changes applied to a group will affect all members of a
            group.
          </Typography.Text>
        </Space>
      </div>
    </div>
  );
};

export default CustomPermissions;
