// import Overview from "./Overview";
import ManageAccessTable from "./ManageAccessTable";
import { StateTypes } from "../../model/data";
import { useSelector } from "react-redux";
import { AccessGuidance } from "./AccessGuidance";

// 5 categories -> all, from_org, external, pending, failed

const userGuidance = {
  desc: "In these pages you can invite new users, manage existing users and set  access permissions.",
  desc1:
    "To the left is a list of all users with access to the platform. To revoke access, click Manage.",
  desc2: "To add new users click ‘Invite Users’ above.",
  desc3:
    "Custom data access permissions can be set for all new and existing users. Dataset access can be set to: hidden, view only or view and export. ",
};

const pendingGuidance = {
  desc: " Pending Invitations are invitations which have been sent to users who have not yet signed up to the platform.",
  desc1: " If you would like to rescind an invite, click on the trash icon. This will invalidate the user’s invite. They will no longer be able to sign up to the platform unless a new invite is sent.", 
  desc2:"To resend an invite, click the arrow icon under Action. This will send the selected user a new Invite Email and temporary sign up code.",
  desc3:"Invites are valid for 365 days.",
  desc4: "Please note, some organisation firewalls can result in users experiencing delayed arrival of external emails. Check spam inbox for emails from admin@advanced-infrastructure.co.uk.",
  desc5: "If you experience any issues, feel free to reach out to support@advanced-infrastructure.co.uk."
};

const ContentWrapper = () => {
  const currentTab = useSelector(
    (state: StateTypes) => state.access.currentTab
  );
  const users: any = useSelector((state: StateTypes) => state.access.userList);

  return (
    <div
      style={{
        width: "100%",
        height: "90vh",
        padding: "1.5rem 2.5rem",
        overflowY: "scroll",
        paddingTop: 0,
      }}
    >
      {/* <Overview users={users} /> */}
      {/* <ManageAccessTable users={users[currentTab]} /> */}
      <div style={{ display: "flex", width: '100%', backgroundColor: '#fff' }}>
        <div style={{width: '74%', marginRight: 10}}>
          <ManageAccessTable users={users[currentTab]} />
        </div>
        <div style={{width: '28%', padding: '10px 0', paddingRight: 10}}>
          {currentTab === 'pending' ? <AccessGuidance guidance={pendingGuidance} /> :
            currentTab === 'users' ? <AccessGuidance guidance={userGuidance} /> : null}
        </div>
      </div>
    </div>
  );
};

export default ContentWrapper;
