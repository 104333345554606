import { createContext, useState } from "react";

type datasetContextType = {
  state: datasetStateTypes;
  layers: any[];
  dfesLayers: any[];
  dfesData: dfesDataTypes;
  dfesConfig: Record<string, any>;
  updateDatasetState: (mapData: any, mapDataset: any) => void;
  updateDfesData: (data: any, type?: string) => void;
  dfesConfigUpdate: (data: any, type?: string) => void;
  addLayer: (data: any, type: string) => void;
  addDfesLayer: (data: any, type: string) => void;
};

type datasetStateTypes = {
  mapData: Record<string, any>;
  mapDataset: Record<string, any>;
};

type dfesDataTypes = {
  name: string;
  data: Record<string, any>;
  selectedType: string;
  selectedTechnology: string;
  selectedScenario: string;
  selectedSubTechnology: string;
};

const datasetState = {
  mapData: {},
  mapDataset: {},
};

const dfesDataState = {
  name: "dfes",
  data: {},
  selectedType: "",
  selectedScenario: "",
  selectedTechnology: "",
  selectedSubTechnology: "",
};

const defaultConfig = {
  opacity: 0.3,
  colorRange: "sequentialGreen1_9",
};

const DatasetContext = createContext<datasetContextType>({
  state: {
    mapData: {},
    mapDataset: {},
  },
  layers: [],
  dfesLayers: [],
  dfesData: dfesDataState,
  dfesConfig: {},
  updateDatasetState(mapData, mapDataset) {},
  updateDfesData(data, type) {},
  dfesConfigUpdate(data, type) {},
  addLayer(data, type) {},
  addDfesLayer(data, type) {},
});

export function DatasetProvider({ children }: any) {
  const [state, setState] = useState<datasetStateTypes>(datasetState);
  const [layers, setLayers] = useState<any[]>([]);
  const [dfesLayers, setDfesLayers] = useState<any[]>([]);
  const [dfesData, setDfesData] = useState<dfesDataTypes>(dfesDataState);
  const [dfesConfig, setDfesConfig] = useState(defaultConfig);

  const updateDatasetState = (a: any, b: any) => {
    setState((pre: any) => ({
      mapData: {
        ...pre.mapData,
        ...a,
      },
      mapDataset: {
        ...pre.mapDataset,
        ...b,
      },
    }));
  };

  const updateDfesData = (data: any, type?: string) => {
    if (type === "reset") {
      setDfesData(dfesDataState);
    } else {
      setDfesData((pre) => ({
        ...pre,
        ...data,
      }));
    }
  };

  const addLayer = (data: any, type: string) => {
    if (type === "new") {
      setLayers((pre) => [...pre, data]);
    } else setLayers(data);
  };

  const addDfesLayer = (data: any, type: string) => {
    if (type === "new") {
      setDfesLayers((pre: any) => {
        return [...pre, data];
      });
    } else setDfesLayers(data);
  };

  const dfesConfigUpdate = (data: any, type?: string) => {
    if (type === "reset") {
      setDfesConfig(defaultConfig);
    } else {
      setDfesConfig((pre) => ({
        ...pre,
        ...data,
      }));
    }
  };

  return (
    <DatasetContext.Provider
      value={{
        state,
        layers,
        dfesLayers,
        dfesData,
        dfesConfig,
        updateDatasetState,
        updateDfesData,
        dfesConfigUpdate,
        addLayer,
        addDfesLayer,
      }}
    >
      {children}
    </DatasetContext.Provider>
  );
}

export default DatasetContext;
