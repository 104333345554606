import { createSlice } from "@reduxjs/toolkit";
import { DisplayEntityTypes } from "./data";

const initialState: DisplayEntityTypes = {
  defaultMapId: "9665304f-606c-45f0-92e6-d1f3670eec7a",
  currentMapId: null,
  currentMapInfo: null,
  maps: [],
  createMapModalVisible: false,
  editMapModalVisible: false,
};

const displayEntityModel = createSlice({
  name: "displayEntity",
  initialState,
  reducers: {
    setMaps(state, { payload }) {
      state.maps = payload;
    },
    setcurrentMapId(state, { payload }) {
      state.currentMapId = payload;
    },
    setCurrentMapInfo(state, { payload }) {
      state.currentMapInfo = payload;
    },
    toggleCreateMapModal(state, { payload }) {
      state.createMapModalVisible = payload;
    },
    toggleEditMapModal(state, { payload }) {
      state.editMapModalVisible = payload;
    },
    addMap(state, { payload }) {
      state.maps.push(payload);
    },
    removeMap(state, { payload }) {
      state.maps = state.maps.filter((map) => map.id !== payload);
    },
    updateMap(state, { payload }) {
      const map = state.maps.filter((m) => m.id === payload.id)[0];
      map.meta.title = payload.title;
      map.meta.description = payload.description;
    },
    deleteMapDatasets(state, { payload }) {
      let datasets = state.currentMapInfo?.datasets;
      if (state.currentMapInfo)
        state.currentMapInfo.datasets =
          datasets?.filter((dataset) => !(dataset.dataset_id in payload)) ?? [];
    },
    updateMapDatasets(state, { payload }) {
      if (state.currentMapInfo) state.currentMapInfo.datasets = payload;
    },
  },
});

export const {
  setMaps,
  setcurrentMapId,
  setCurrentMapInfo,
  toggleCreateMapModal,
  toggleEditMapModal,
  addMap,
  removeMap,
  updateMap,
  deleteMapDatasets,
  updateMapDatasets,
} = displayEntityModel.actions;
export default displayEntityModel.reducer;
