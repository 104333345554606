import { createSlice } from "@reduxjs/toolkit";
import { globalType } from "./data";

const initialState: globalType = {
  activePage: "Home",
  pageIndex: 6,
  error: {
    status: 0,
    message: "",
  },
  mainSidebarVisible: false,
};

const globalModel = createSlice({
  name: "global",
  initialState,
  reducers: {
    queryActivePage(state, { payload: { pageName, index } }) {
      state.activePage = pageName;
      state.pageIndex = index;
    },
    raiseError(state, { payload }) {
      state.error.status = payload.status;
      state.error.message = payload.message;
    },
    setMainSidebarVisible(state, { payload }) {
      state.mainSidebarVisible = payload;
    },
  },
});

export const { queryActivePage, raiseError, setMainSidebarVisible } =
  globalModel.actions;
export default globalModel.reducer;
