import { useEffect } from "react";
import { getUsersGroupByCategory } from "../components/access/utils";
// mock user data
// import { USERS } from "../components/access/data";
// import AccessHeader from "../components/access/AccessHeader";
import AccessMenu from "../components/access/AccessMenu";
import "../components/components.css";
import { Layout, message } from "antd";
import ContentWrapper from "../components/access/ContentWrapper";
import { Route } from "react-router-dom";
import InviteUsers from "../components/access/InviteUsers";
import ManageAccess from "../components/access/ManageAccess";
import BasePermissions from "../components/access/BasePermissions";
import CustomPermissions from "../components/access/CustomPermissions";
import NoPage from "./NoPage";
// import { AccessUsers } from "../model/data";
import {
  setMainSidebarVisible,
  updateLoadingUserList,
  updateUserList,
} from "../model";
import { useDispatch } from "react-redux";
import { setPermissionGroups } from "../model";
import { db } from "../services/firebase";
import { isEmpty } from "lodash";
import { Md5 } from "ts-md5";

const { Content } = Layout;

const Access = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const getUserData = async () => {
      dispatch(updateLoadingUserList(true));
      db.collection("user").onSnapshot(async (snapshot) => {
        const users: any[] = [];
        snapshot.docs.forEach(async (doc) => {
          const user = doc.data();
          if (!user?.email) return;
          const name = user.email?.match(/[a-zA-z0-9._-]+/)[0];
          const inviteDate = user.invite?.invited_on.seconds
            ? new Date(user.invite.invited_on.seconds * 1000).toDateString()
            : new Date(user.invite?.invited_on);
          const details = {
            key: user.uid ?? Md5.hashStr(user.email.toLowerCase()),
            name:
              user.displayName ?? name
                ? name[0].toUpperCase() + name.substring(1)
                : "",
            email: user.email,
            pending: !user.active,
            failed: false,
            permissionGroup: user.permissionGroup ?? "",
            inviteDate,
            expiredOn: "",
          };
          users.push(details);
        });

        const result = await Promise.all(
          users.map(async (item: any, i: number) => {
            const permissionGroup = isEmpty(item.permissionGroup)
              ? ""
              : (await item.permissionGroup.get()).data()?.meta?.name ??
                "Invalid Group";
            return { ...item, permissionGroup };
          })
        );

        dispatch(updateLoadingUserList(false));
        const fetchedUsers = getUsersGroupByCategory(result);
        dispatch(updateUserList(fetchedUsers));
      });
    };

    getUserData().catch((err) => {
      dispatch(updateLoadingUserList(false));
      message.error("Error Occurred! Try again later.");
    });
  }, [dispatch]);

  useEffect(() => {
    dispatch(setMainSidebarVisible(true));
  }, [dispatch]);

  useEffect(() => {
    db.collection("groups").onSnapshot((snapshot: any) => {
      let _groups: any = [];
      snapshot.docs.forEach((doc: any) => {
        const _data = doc.data();
        _groups.push({
          id: doc.id,
          name: _data.meta.name,
          description: _data.meta.description,
        });
      });
      dispatch(setPermissionGroups(_groups));
    });
  }, [dispatch]);

  const menuRoutes = [
    "/access",
    "/access/from-org",
    "/access/external-collab",
    "/access/pending-invites",
    "/access/failed-invites",
  ];

  const customPermissionRoutes = [
    "/access/invite-users/custom-permissions",
    "/access/:userId/manage-access/custom-permissions",
  ];

  return (
    <Layout style={{ height: "100vh" }} className="access">
      {/* <Sider className="access-page-sider"> */}
      <AccessMenu />
      {/* </Sider> */}
      <Layout>
        <Content>
          <Route path={menuRoutes} exact>
            {/* <AccessHeader heading="Overview" /> */}
            <ContentWrapper />
          </Route>
          <Route path="/access/invite-users" exact>
            {/* <AccessHeader heading="Invite Users" showBackBtn showBreadcrumb /> */}
            <InviteUsers />
          </Route>
          <Route path="/access/:userId/manage-access" exact>
            {/* <AccessHeader heading="Manage Access" showBackBtn showBreadcrumb /> */}
            <ManageAccess />
          </Route>
          <Route path="/access/base-permissions" exact>
            {/* <AccessHeader
              heading="Manage Base Permissions"
              showBackBtn
              showBreadcrumb
            /> */}
            <BasePermissions />
          </Route>
          <Route path={customPermissionRoutes} exact>
            {/* <AccessHeader heading="New Group" showBackBtn showBreadcrumb /> */}
            <CustomPermissions />
          </Route>
          <Route path="*">
            <NoPage />
          </Route>
        </Content>
      </Layout>
    </Layout>
  );
};

export default Access;
