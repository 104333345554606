import { createSlice } from "@reduxjs/toolkit";
import { AnalyticsTypes } from "./data";

const initialState: AnalyticsTypes = {
  analyticsDatasets: [],
  column_data: {},
  query_table_data: [],
  selectedDatasets: [],
  column_headers: [],
  analyticsExamples: [],
};

const analyticsModel = createSlice({
  name: "analytics",
  initialState,
  reducers: {
    setSelectedDatasets(state, { payload }) {
      state.selectedDatasets = payload;
    },
    setAnalyticsDatasets(state, { payload }) {
      state.analyticsDatasets = payload;
    },
    setColumnData(state, { payload }) {
      const { column_data } = payload;
      state.column_data = column_data;
    },
    setQueryTableData(state, { payload }) {
      const { query_table_data } = payload;
      state.query_table_data = query_table_data;
    },
    setColumnHeaders(state, { payload }) {
      state.column_headers = payload;
    },
    setAnalyticsExamples(state, { payload }) {
      state.analyticsExamples = payload;
    },
    resetAnalytics(state) {
      state.column_data = {};
      state.query_table_data = [];
      state.selectedDatasets = [];
      state.column_headers = [];
    },
  },
});

export const {
  setSelectedDatasets,
  setAnalyticsDatasets,
  setColumnData,
  setQueryTableData,
  setColumnHeaders,
  resetAnalytics,
  setAnalyticsExamples,
} = analyticsModel.actions;

export default analyticsModel.reducer;
