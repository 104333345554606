import { useEffect, useState } from "react";
import { Card, Input, Table } from "antd";
import { SearchOutlined } from "@ant-design/icons";
// import { useHistory } from "react-router-dom";
// import Styled from "styled-components";
import { useSelector } from "react-redux";
import { StateTypes } from "../../model/data";
import { getColumns } from "./utils";
import RevokeAccessModal from "./RevokeAccessModal";

// const TitleText = Styled(Typography.Title)`
// font-size: 1.3rem !important;
// margin: 0px !important;
// `;

const ManageAccessTable = ({ users }: any) => {
  const loading = useSelector(
    (state: StateTypes) => state.access.loadingUserList
  );
  const [columns, setColumns] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);

  const currentTab = useSelector(
    (state: StateTypes) => state.access.currentTab
  );

  // const history = useHistory();

  useEffect(() => {
    const tableColumns = getColumns(currentTab);
    // @ts-ignore
    setColumns(tableColumns);
  }, [currentTab]);

  useEffect(() => {
    if (!users) return;
    // const updatedUsers = users?.map((user: any) => {
    //   const date = new Date(user?.inviteDate);
    //   const expirationDate = new Date(date.getTime() + (90 * 24 * 60 * 60 * 1000));
    //   const currentDate = new Date();
    //   const isExpired = currentDate.getTime() > expirationDate.getTime();
    //   return {
    //     ...user,
    //     expired: isExpired ? "Yes" : "No",
    //   }
    // })
    // setFilteredUsers(updatedUsers);
    setFilteredUsers(users);
  }, [users]);

  const handleSearch = (event: any) => {
    const searchedText = event.target.value;
    const filteredData = users.filter(
      (user: any) =>
        user.name.toLowerCase().includes(searchedText.toLowerCase()) ||
        user.email.toLowerCase().includes(searchedText.toLowerCase())
    );
    setFilteredUsers(filteredData);
  };

  // const handleInviteUsers = () => {
  //   history.push("/access/invite-users");
  // };

  return (
    <div style={{ marginTop: "1rem" }}>
      {/* <Space
        direction="horizontal"
        style={{
          width: "100%",
          justifyContent: "space-between",
          marginBottom: "1rem",
        }}
      >
        <TitleText>Manage Access</TitleText>
        {!["failed", "pending"].includes(currentTab) && (
          <Button
            type="primary"
            className="primary-btn"
            onClick={handleInviteUsers}
          >
            Invite users
          </Button>
        )}
      </Space> */}

      <Card
        className="manage-access-card"
        style={{ border: "none" }}
        bodyStyle={{
          padding: "1rem 1.5rem",
          height: "73vh",
        }}
      >
        <div
          className="input-container"
          style={{
            backgroundColor: "#DBE0EC",
            borderRadius: 4,
            marginBottom: 24,
            height: 40,
            border: "1px solid #6A7485",
          }}
        >
          <SearchOutlined />
          <Input
            placeholder="Search by name or email"
            style={{ width: "100%" }}
            onChange={handleSearch}
          />
        </div>

        <Table
          loading={loading}
          dataSource={filteredUsers}
          columns={columns}
          size="large"
          className="manage-access-table"
          // scroll={{ y: "35vh" }}
          scroll={{ y: "53vh" }}
          style={{ cursor: "pointer" }}
          pagination={false}
        />
      </Card>
      <RevokeAccessModal />
    </div>
  );
};

export default ManageAccessTable;
