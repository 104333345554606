import { InfoCircleFilled } from "@ant-design/icons";
import { Space, Typography } from "antd";

export const AccessGuidance = ({ guidance }: any) => {
  return (
    <div>
      <Space
        direction="vertical"
        size={16}
        style={{
          borderTop: "4px solid #4485EA",
          padding:"15px 10px 20px 20px",
          width: "100%",
          backgroundColor: "#F3F4F8",
          height: "70vh",
          overflowY: "scroll",
          marginTop: 20
        }}
      >
        <Typography.Title level={4} style={{ margin: 0 }}>
          <InfoCircleFilled
            style={{ color: "var(--default-green)", marginRight: 10 }}
          />
          Guidance
        </Typography.Title>
        <Typography.Text
          style={{
            fontSize: 16,
            margin: 0,
            fontWeight: 500,
            lineHeight: 1.5,
          }}
        >
          {guidance.desc}
        </Typography.Text>
        <Typography.Text
          style={{
            fontSize: 16,
            margin: 0,
            fontWeight: 500,
            lineHeight: 1.5,
          }}
        >
          {guidance.desc1}
        </Typography.Text>
        <Typography.Text
          style={{
            fontSize: 16,
            margin: 0,
            fontWeight: 500,
            lineHeight: 1.5,
          }}
        >
          {guidance.desc2}
        </Typography.Text>
        <Typography.Text
          style={{
            fontSize: 16,
            margin: 0,
            fontWeight: 500,
            lineHeight: 1.5,
          }}
        >
          {guidance.desc3}
        </Typography.Text>
        <Typography.Text
          style={{
            fontSize: 16,
            margin: 0,
            fontWeight: 500,
            lineHeight: 1.5,
          }}
        >
          {guidance.desc4}
        </Typography.Text>
        <Typography.Text
          style={{
            fontSize: 16,
            margin: 0,
            fontWeight: 500,
            lineHeight: 1.5,
          }}
        >
          {guidance.desc5}
        </Typography.Text>
      </Space>
    </div>
  );
};