import {
  Button,
  Dropdown,
  Menu,
  Space,
  Tooltip,
  message,
  notification,
} from "antd";
import { RetweetOutlined, SettingOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getActionItems } from "./utils";
import { StateTypes } from "../../model/data";
import { useHistory } from "react-router-dom";
import {
  updateAccessSeletedUser,
  updateCurrentAccessTab,
  updateRevokeDatasetAccessVisible,
} from "../../model";
import axios from "axios";

const ActionColumn = ({ user }: any) => {
  const [menuItems, setMenuItems] = useState<any[]>([]);
  const currentTab = useSelector(
    (state: StateTypes) => state.access.currentTab
  );
  const [resending, setResending] = useState<boolean>(false);

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    const items = getActionItems(currentTab);
    setMenuItems(items);
  }, [currentTab]);

  const handleManageAccess = () => {
    dispatch(updateAccessSeletedUser(user));
    history.push(`/access/${user.key}/manage-access`);
    dispatch(updateCurrentAccessTab("manage-access"));
  };

  const handleRevokeAccess = () => {
    dispatch(updateAccessSeletedUser(user));
    dispatch(updateRevokeDatasetAccessVisible(true));
  };

  const handleResendInvite = async () => {
    const username = user?.key;
    const email = user?.email;
    const request_body_obj = {
      username,
      email,
    };
    setResending(true);
    try {
      const resend_invite = await axios.post(
        `${process.env.REACT_APP_RESEND_PENDING_INVITE}`,
        request_body_obj
      );
      const messageValue = resend_invite?.data?.data?.message;
      if (messageValue) {
        message.success(`Invite resent successfully to ${email}`);
      }
    } catch (err) {
      notification.error({
        message: "Network Error!",
        description: "Could not send invite, Try again.",
        placement: "bottomRight",
      });
    } finally {
      setResending(false);
    }
  };

  const handleRemoveUser = async () => {
    const username = user?.key;
    const request_body_obj = {
      username,
    };
    const delete_invite = await axios.post(
      `${process.env.REACT_APP_DELETE_PENDING_INVITE}`,
      request_body_obj
    );
    console.log(delete_invite);
    message.success("Invitation removed successfully");
  };

  const handleMenuClick = (selectedMenu: any) => {
    switch (selectedMenu.key) {
      case "manage-access":
        handleManageAccess();
        break;
      case "revoke-access":
        handleRevokeAccess();
        break;
      case "resend-invite":
        handleResendInvite();
        break;
      case "remove":
        handleRemoveUser();
        break;
    }
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      {menuItems.map((menu: any) => (
        <Menu.Item key={menu.key}>{menu.label}</Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Space direction="horizontal" style={{ width: "100%", marginLeft: 8 }}>
      {menuItems.map((c: any) =>
        c.key === "manage-access" ? (
          <Dropdown
            overlay={menu}
            placement="bottomRight"
            trigger={["click"]}
            arrow
          >
            <Button type="text" icon={<SettingOutlined />} />
          </Dropdown>
        ) : c.key === "resend-invite" ? (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Tooltip title="Resend Invite">
              <Button
                type="text"
                size="small"
                loading={resending}
                icon={<RetweetOutlined />}
                onClick={() => handleResendInvite()}
                style={{ color: "#109668" }}
              />
            </Tooltip>
            {/* Do this later in the future */}
            {/* <Tooltip title="Remove Invitation">
              <DeleteOutlined
                style={{ fontSize: 16, color: "#FD625E" }}
                onClick={() => handleRemoveUser()}
              />
            </Tooltip> */}
          </div>
        ) : null
      )}
    </Space>
  );
};

export default ActionColumn;
