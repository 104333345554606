import { SearchOutlined } from "@ant-design/icons";
import { Button, Card, Input, Space, Typography } from "antd";
import { useState } from "react";
import { useSelector } from "react-redux";
import { StateTypes } from "../../model/data";
import Datasets from "./Datasets";

const BasePermissions = () => {
  const [searchedText, setSearchedText] = useState("");
  const [confirmText, setConfirmText] = useState("");

  const { categories } = useSelector((state: StateTypes) => state.deckgl);
  const handleSearch = (e: any) => {
    setSearchedText(e.target.value);
  };

  const handleApplyChanges = () => {};

  return (
    <div
      style={{
        width: "100%",
        height: "90vh",
        padding: "1.5rem 2.5rem",
      }}
    >
      <Card
        style={{ width: "100%", height: "100%" }}
        bodyStyle={{ padding: "1.5rem 2.5rem", height: "100%" }}
      >
        <div className="input-container">
          <SearchOutlined />
          <Input
            placeholder="Search datasets"
            style={{ width: "100%" }}
            value={searchedText}
            onChange={handleSearch}
          />
        </div>
        <Space
          direction="horizontal"
          style={{ width: "100%", height: "90%" }}
          className="base-permissions"
        >
          <Datasets searchedText={searchedText} categories={categories} />
          <div
            style={{
              paddingLeft: "2rem",
              display: "flex",
              flexDirection: "column",
              height: "100%",
            }}
          >
            <div style={{ flex: 1 }}>
              <div className="info-card">
                <Typography.Title level={5}>Note:</Typography.Title>
                <Typography.Paragraph>
                  Lörem ipsum vadat tetrassa antissa, nyvålingar ryr. Doktig
                  dybölig, ad. Pesm biovältare, respektive kvasirar jys.
                  Beryning retyp fäskapet onade. Du kan vara drabbad.{" "}
                </Typography.Paragraph>
                <Typography.Paragraph>
                  Please ensure you confirm the permission provisioned before
                  continuining with these changes. Type “confirm” to apply
                  changes{" "}
                </Typography.Paragraph>
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div
                className="input-container"
                style={{ marginBottom: 0, width: 150 }}
              >
                <Input
                  placeholder="Type confirm"
                  value={confirmText}
                  onChange={(e) => setConfirmText(e.target.value)}
                />
              </div>
              <Button
                type="primary"
                className="primary-btn"
                disabled={confirmText.toLowerCase() !== "confirm"}
                onChange={handleApplyChanges}
              >
                Apply changes
              </Button>
            </div>
          </div>
        </Space>
      </Card>
    </div>
  );
};

export default BasePermissions;
