import {
  DownOutlined,
  EditOutlined,
  TeamOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import { PageHeader, Space, Typography } from "antd";

interface PermissionGroupProps {
  name: string;
  description: string;
  isSelected?: boolean;
  editHandler?: any;
  groupid?: string;
}

const PermissionGroup = ({
  name,
  description,
  isSelected,
  editHandler,
  groupid,
  ...props
}: PermissionGroupProps) => {
  const getIcon = (name: string) => {
    if (name === "New Group") return <UsergroupAddOutlined />;
    return <TeamOutlined />;
  };
  return (
    <div
      {...props}
      className="permission-group"
      style={{
        width: "100%",
        marginBottom: isSelected ? "1rem" : "0",
        backgroundColor: isSelected ? "#0F966814" : "initial",
        cursor: "pointer",
        borderBottom: "1px solid lightgrey",
        marginTop: 10,
      }}
    >
      <PageHeader
        backIcon={getIcon(name)}
        onBack={() => null}
        title={
          <div style={{display: 'flex', alignItems: 'center'}}>
          <Typography.Title level={5} style={{ marginBottom: 0 }}>
            {name}
          </Typography.Title>
          {name !== "New Group" ? (
             <div>
             <a
               href="/access/:userId/manage-access/custom-permissions"
               onClick={() => editHandler(groupid)}
               style={{
                 // backgroundColor: "var(--default-green)",
                 color: "var(--default-green)",
                 textDecoration: "underline",
                 fontWeight: 600,
                 fontSize: 14,
                 marginLeft: 15,
               }}
             >
               Edit
             </a>
             <EditOutlined style={{color: "var(--default-green)", marginLeft: 5, fontSize: 14}}/>
             </div>
            ) : null}
          </div>
        }
        extra={
          <Space>
            
            {isSelected && <DownOutlined />}
          </Space>
        }
        style={{ padding: "8px 24px" }}
      >
        <Typography.Paragraph
          style={{
            marginBottom: 0,
            color: "#676767",
            maxWidth: "40vw",
            textAlign: "justify",
            paddingTop: 0,
          }}
          ellipsis={!isSelected}
        >
          {description}
        </Typography.Paragraph>
      </PageHeader>
    </div>
  );
};

export default PermissionGroup;