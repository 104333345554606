import { createSlice } from "@reduxjs/toolkit";
import { AccessTypes, PermissionGroupTypes } from "./data";

const initialState: AccessTypes = {
  currentTab: "users",
  selectedUser: null,
  manageDatasetAccessVisible: false,
  revokeDatasetAccessVisible: false,
  inviteUser: { emails: [] },
  defaultPermission: {
    id: "sOwnlZEfA3B9TLBXstOG",
    name: "Default Permission",
    description:
      "Users with default permissions have view and export access for datasets with open licenses only. Users in this group will be unable to view or export datasets under different license conditions, such as SSEN's LV asset data and potential for renewables layers.",
  },
  permissionGroups: [
    {
      id: "new-group-custom-id",
      name: "New Group",
      description: "Create new permission group",
    },
  ],
  userList: {
    users: [],
    from_org: [],
    external: [],
    pending: [],
    failed: [],
  },
  loadingUserList: false,
};

const accessModel = createSlice({
  name: "access",
  initialState,
  reducers: {
    updateUserList(state, { payload }) {
      state.userList = payload;
    },
    updateLoadingUserList(state, { payload }) {
      state.loadingUserList = payload;
    },
    updateCurrentAccessTab(state, { payload }) {
      state.currentTab = payload;
    },
    updateAccessSeletedUser(state, { payload }) {
      state.selectedUser = payload;
    },
    updateManageDatasetAccessVisible(state, { payload }) {
      state.manageDatasetAccessVisible = payload;
    },
    updateRevokeDatasetAccessVisible(state, { payload }) {
      state.revokeDatasetAccessVisible = payload;
    },
    addEmail(state, { payload }) {
      const emails = state.inviteUser.emails;
      const email = payload.trim();
      if (!emails.includes(email)) emails.push(email);
    },
    removeEmail(state, { payload }) {
      const email = payload.trim();
      state.inviteUser.emails = state.inviteUser.emails.filter(
        (item) => item !== email
      );
    },
    clearEmails(state) {
      state.inviteUser.emails = [];
    },
    setPermissionGroups(state, { payload }) {
      state.permissionGroups.unshift(
        ...payload.filter(
          (fetchedGroup: PermissionGroupTypes) =>
            !state.permissionGroups.some(
              (group) => group.id === fetchedGroup.id
            )
        )
      );
    },
  },
});

export const {
  updateUserList,
  updateLoadingUserList,
  updateCurrentAccessTab,
  updateAccessSeletedUser,
  updateManageDatasetAccessVisible,
  updateRevokeDatasetAccessVisible,
  addEmail,
  removeEmail,
  clearEmails,
  setPermissionGroups,
} = accessModel.actions;
export default accessModel.reducer;
